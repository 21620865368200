import firebase from '../firebase';
import { User } from 'firebase';
const db = firebase.firestore();

export interface IUser {
  displayName: string | null;
  email: string | null;
  userID: string;
};

export function mapUserData(user: User): IUser {
  return {
    displayName: user.displayName,
    email: user.email,
    userID: user.uid,
  };
}

export function setUserData(user: IUser) {
  return db.collection('users').doc(user.userID).set(user)
    .then(() => console.log('User set'))
    .catch((e) => console.error(`Error setting user: ${e}`));
}

export function getUserDoc(id: string) {
  return db.collection('users').doc(id);
}

export function getUserData(id: string) {
  return getUserDoc(id).get()
}

export function signInUser(user: User) {
  return setUserData(mapUserData(user)).catch((e) => console.log(`Could not log in user: ${e}`));
}

import React, { FunctionComponent } from 'react';
import { Button, Theme, withStyles, StyledComponentProps } from "@material-ui/core";
import { ILipid } from "../lipids/lipids.model";
import { Confession } from "../confession/Confession";
import { LipidChart } from "../chart/LipidChart";
import "./Content.css";

const styles = (theme: Theme) => ({element: { margin: theme.spacing.unit * 2 }});

type ContentProps = StyledComponentProps & {
  lipids: ILipid[];
  getCallback: () => void;
}
const ContentComponent: FunctionComponent<ContentProps> = ({lipids, getCallback, classes}) => {
  const elementClass = classes && classes.element || '';
  return (
    <div className="Content-wrapper">
      <div className={elementClass}><Confession getCallback={getCallback}/></div>
      <div className={elementClass}><LipidChart lipids={lipids}/></div>
    </div>
  );
}

export const Content = withStyles(styles)(ContentComponent);

import { getUserDoc } from '../user/user.model';

export interface ILipid {
  date: Date;
  fat: boolean;
}

export function addLipid(id: string, fat: boolean) {
  return getUserDoc(id).collection('lipids').add({fat, date: new Date()})
    .then(() => console.log(`Lipid added`))
    .catch((e) => console.error(`Error adding lipid: ${e}`));  
}

export function getLipids(id: string): Promise<ILipid[]> {
  const d = getUserDoc(id);
  return d.collection('lipids').get()
    .then((snapshot) => {
      const lipids: ILipid[] = [];
      snapshot.forEach((doc) => {
        const {date, fat} = doc.data();
        lipids.push({ date: new Date(date.seconds * 1000), fat});
      });
      return lipids;
    });
}
import React, { FunctionComponent } from 'react';
import { Button, Theme, StyledComponentProps } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { loggedInUserID } from "../auth/auth";
import { addLipid } from "../lipids/lipids.model";
import "./Confession.css";

const addFat = (fat: boolean) => {
  const uid = loggedInUserID();
  return uid === null ? Promise.reject() : addLipid(uid, fat); 
};

const styles = (theme: Theme) => ({button: { margin: theme.spacing.unit }});

type ConfessionComponentProps = StyledComponentProps & {getCallback: () => void} 
const ConfessionComponent: FunctionComponent<ConfessionComponentProps> = ({getCallback, classes}) => {
  const className = classes && classes.button || '';
  const doAdd = (fat: boolean) => addFat(fat).then(getCallback);
  return (
    <div className="Confession-wrapper">
      <Button className={className} variant="contained" color="primary" onClick={() => doAdd(false)}>Not a Fatty</Button>
      <Button className={className} variant="contained" color="secondary" onClick={() => doAdd(true)}>Fatty</Button>
    </div>
  );
}

export const Confession = withStyles(styles)(ConfessionComponent);
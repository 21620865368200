import firebase from '../firebase';
import { IUser, mapUserData, signInUser } from '../user/user.model';

const googleProvider = new firebase.auth.GoogleAuthProvider();

export function signIn() {
  return firebase.auth().signInWithPopup(googleProvider)
    .then((u) => u.user ? signInUser(u.user) : Promise.reject())
    .catch(() => console.error('Could not sign in'));
}

export function signOut() {
  return firebase.auth().signOut().catch(() => console.error('Could not sign out'));
}

export function isSignedIn() {
  const user = currentUser();
  return !!user;
}

export function currentUser() {
  const auth = firebase.auth();
  return auth.currentUser;
}

export function loggedInUserID() {
  const u = currentUser();
  return u ? u.uid : null;
}

import React, { Component } from 'react';
import './App.css';
import { isSignedIn, signIn, signOut, currentUser, loggedInUserID } from './auth/auth';
import { IUser, mapUserData } from './user/user.model';
import firebase from './firebase';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';
import { Button, AppBar, Toolbar, Typography } from '@material-ui/core';
import { Content } from './content/Content';
import { ILipid, getLipids } from './lipids/lipids.model';

const theme = createMuiTheme({
  palette: {
    primary: green,
    secondary: orange
  },
  typography: {
    useNextVariants: true,
  }
});

interface IAppProps {}

interface IAppState {
  isSignedIn: boolean;
  user: IUser | null;
  loading: boolean;
  lipids: ILipid[]
}


const signedInUser = () => {
  const u = currentUser();
  return u ? mapUserData(u) : null;
};

const getFats = () => {
  const uid = loggedInUserID();
  return uid === null ? Promise.reject() : getLipids(uid); 
}

class App extends Component<IAppProps, IAppState> {
  constructor(props: IAppProps) {
    super(props);
    this.doSignIn = this.doSignIn.bind(this);
    this.doSignOut = this.doSignOut.bind(this);
    this.setSignedIn = this.setSignedIn.bind(this);
    this.getLipids = this.getLipids.bind(this);

    firebase.auth().onAuthStateChanged(() => {
      this.setSignedIn(); 
      if (this.state.isSignedIn) {
        this.getLipids();
      }
    });

    this.state = {
      loading: true,
      isSignedIn: isSignedIn(),
      user: signedInUser(),
      lipids: []
    }
  }

  doSignIn() {
    this.setState({ loading: true });
    signIn();
  }

  doSignOut() {
    this.setState({ loading: true });
    signOut();
  }

  setSignedIn() {
    this.setState({ isSignedIn: isSignedIn(), user: signedInUser(), loading: false });
  }

  getLipids() {
    getFats().then((lipids) => this.setState({lipids}));
  }

  render() {
    const isSignedIn = this.state.isSignedIn;
    const user = this.state.user;
    const loading = this.state.loading;
    const lipids = this.state.lipids;
    const userLabel = isSignedIn && user ? <label  className="App-user-label">Welcome, {user.displayName}</label> : null;
    const button =
      isSignedIn
      ? <Button color="inherit" onClick={this.doSignOut} disabled={loading}>Log out</Button>
      : <Button color="inherit" onClick={this.doSignIn} disabled={loading}>Log in</Button>;

    return (
      <div className="App">
        <MuiThemeProvider theme={theme}>
          <AppBar position="static">
            <Toolbar>
              <Typography variant="h6" color="inherit" className="App-title">
                Fatty
              </Typography>
              <Typography variant="body1" color="inherit">
                {userLabel}
              </Typography>
              {button}
            </Toolbar>
          </AppBar>
          <div className="App-content">
            {isSignedIn
            ? <Content lipids={lipids} getCallback={this.getLipids}/> 
            : <Typography variant="h3">Please Sign In</Typography>}
            
          </div>
        </MuiThemeProvider>
      </div>
    );
  }
}

export default App;

import React, { FunctionComponent } from 'react';
import { LineChart } from 'react-chartkick';
import { ILipid } from '../lipids/lipids.model';

type DataPoints = { [key: string]: number };

const addZero = (v: number) => v < 10 ? `0${v}` : '' + v;
const fmtDate = (d: Date) => `${d.getFullYear()}-${addZero(d.getMonth() + 1)}-${addZero(d.getDate())}`

function mapLipidsToChartData(lipids: ILipid[]): DataPoints {
  let currentStanding = 0;
  return lipids.reduce((acc: DataPoints, {fat, date}) => {
    currentStanding = currentStanding + (fat ? -1 : 1);
    acc[fmtDate(date)] = currentStanding;
    return acc;
  }, {});
}

export const LipidChart: FunctionComponent<{ lipids: ILipid[] }> = ({lipids}) => {
  return (
    <LineChart data={mapLipidsToChartData(lipids)} ></LineChart>
  )
}

